<template>
  <div class="container">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">
            <b-icon icon="car" size="is-large"></b-icon>
            Moje zamówienia
          </h1>
        </div>
      </div>
      <div class="level-right mt-4 mr-5">
          <b-button 
          @click="$router.push({name: 'Cars'})"
          type="is-primary" rounded><b-icon  class="mr-2" icon="arrow-left" size="is-small"></b-icon> &nbsp;Powrót</b-button>
      </div>
    </div>
    <hr />

    <div v-if="hasOrders">
      <b-table :data="orders.data" :loading="isTableLoading" striped>
        <b-table-column
          field="uid"
          label="Nr zamówienia"
          width="150"
          centered
          numeric
          v-slot="props"
        >
          {{ props.row.uid }}
        </b-table-column>
        <b-table-column field="my_car_id" label="Pojazd" v-slot="props">
          {{ props.row.my_car_id }}
        </b-table-column>
        <b-table-column field="company_id" label="Usługodawca" v-slot="props">
          {{ props.row.company_id }}
        </b-table-column>
        <b-table-column
          field="booking_time"
          label="Data zlecenia"
          v-slot="props"
        >
          {{ props.row.booking_time }}
        </b-table-column>
        <b-table-column field="status" label="Status" v-slot="props">
          {{ props.row.status.name }}
        </b-table-column>
        <b-table-column field="summary_value" label="Cena" v-slot="props">
          {{ props.row.summary_value }} zł
        </b-table-column>
        <b-table-column label="Akcje" centered v-slot="props">
          <b-button
            type="is-primary"
            icon-left="magnify"
            size="is-small"
            tag="router-link"
            :to="{ name: 'Order', params: { id: props.row.uid } }"
            rounded
          >
            Szczegóły
          </b-button>
        </b-table-column>
      </b-table>
      <b-pagination
        v-model="current"
        :total="total"
        :per-page="per_page"
        @change="onPageChange"
      ></b-pagination>
    </div>
    <div v-else>Brak historii eksploatacji pojazdów.</div>
  </div>
</template>
<script>
export default {
  created() {
    this.isTableLoading = true;
    const ordersContainer = this.$store.getters["clientOrders/getOrders"];
    this.current = ordersContainer.current_page;
    this.total = ordersContainer.total;
    this.per_page = ordersContainer.per_page;
    this.paginationLinks = ordersContainer.links;

    let carIdList = ordersContainer.data.map((order) => order.my_car_id);
    const cardIdListUnique = [...new Set(carIdList)]; // filtrowanie cardIdList z powtarzających się id
    cardIdListUnique;
    let companyIdList = ordersContainer.data.map((order) => order.company_id);
    const companyIdListUnique = [...new Set(companyIdList)]; // filtrowanie companyIdList z powtarzających się id
    this.isTableLoading = false;
    companyIdListUnique;
    //console.log(cardIdListUnique);
    //console.log(companyIdListUnique);
  },

  data() {
    return {
      total: null,
      current: null,
      per_page: null,
      paginationLinks: null,
      isTableLoading: false,
    };
  },

  methods: {
    onPageChange(value) {
      this.isTableLoading = true;
      //console.log(value);
      const pageUrl = this.paginationLinks[value].url;
      //console.log(this.paginationLinks[value].url);
      this.$store
        .dispatch("clientOrders/getOrdersPage", pageUrl)
        .then((res) => {
          res;
          //console.log(res);
        })
        .catch()
        .finally(() => {
          this.isTableLoading = false;
        });
    },
  },

  computed: {
    orders() {
      return this.$store.getters["clientOrders/getOrders"];
    },

    hasOrders() {
      return this.orders.data !== undefined && this.orders.data.length > 0;
    },
  },
};
</script>